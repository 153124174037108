import React from 'react';
import { Zaposlitev } from 'components/Zaposlitev';

export default () => (
  <Zaposlitev>
    <h1>Jobs</h1>
    <br />
    <br />
    <h2>Data Science Engineer</h2>
    <p>
      We are now looking for a new member to join our team. A Data Scientist, a passionate and driven learner who will
      help us maintain and tune the already set up and functioning system. Of course, if you think we need to reinvent
      ourselves, we're open to research and discussions any day of the week :)
    </p>

    <p>The desired candidate must have:</p>
    <ul>
      <li>at least 3+ years of experience in data science and machine learning (ML),</li>
      <li>very good knowledge of Python programming language,</li>
      <li>knowledge and experience of pandas, scikit-learn, and statsmodels,</li>
      <li>
        hands-on experience in modelling and developing advanced interactive dashboards using BI tools such as Tableau
      </li>
      <li>
        Bachelor of Science in Mathematics / Statistics, Computer Science / Engineering, or related field; equivalent
        experience acceptable{' '}
      </li>
      <li>very good written and spoken technical English</li>
    </ul>
    <p>A plus would be:</p>
    <ul>
      <li>previous work in the electrical energy sector, </li>
      <li>very good written and spoken Slovenian language,</li>
      <li>publication/software product record demonstrating innovative research.</li>
    </ul>
    <p>What we offer:</p>
    <ul>
      <li>competitive salary package that depends on your experience (starting at 3000 gross), </li>
      <li>working with a great team and some of the best individuals in the electrical energy sector in the EU,</li>
      <li>learning budget (conferences and mentors available in Slovenia and Europe), </li>
      <li>
        remote work (full-time engagement preferred), once the pandemic will be over we will offer you two office
        locations if you'll prefer to work in an office setting,
      </li>
      <li>selecting your preferred equipment,</li>
      <li>bonus for exceptional performance.</li>
    </ul>
    <br />
    <br />
    <h3>How to Apply</h3>
    <p>
      Send us your most impressive projects to convince us you are the best candidate for the job. Excite us with your
      innovations and hobby projects. Please send your applications to{' '}
      <a href="mailto:jobs@enetra.si">jobs@enetra.si</a>.
    </p>
    <p>Date and place: Ljubljana, 1. 12. 2022</p>
  </Zaposlitev>
);
